/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import '../App.css';
import logoPic from '../assets/logo.png';
import RICHARD from '../assets/RICHARD-removebg.png';
import GARY from '../assets/GARY-removebg.png';
import gloves from '../assets/boxing-gloves.png';
import chest from '../assets/treasure-chest.png';
import { base } from 'wagmi/chains';
import Navigation from '../components/Navigation';
import $ from 'jquery';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 1500);
});

const Vs = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	return (
		<div class="allWrap">
			<div class="light">

				<div className='cont3'>

					<Navigation />

					<div className='selectSectionMain3'>

						<img id="selectSectionMain3Img1" src={RICHARD} />
						<img id="selectSectionMain3Img2" src={GARY} />

					</div>

					<div className='combat'>
						<button class="button">
							<p class="title">COMBAT</p>
							<img className='combatImg'
								src={gloves}
								alt="gloves"
							/>
							<p class="description">Victory favors the <br />fearless</p>
						</button>

					</div>
				</div>

				<div>

				</div>

			</div>


		</div >
	)

}
export default Vs;
